<template>
  <div>
    <Header
      :showBackButton="true"
      :showLogo="true"
      :menuColor="'var(--primary)'"
      class="page-anim1"
    />

    <section>
      <div class="container">
        <div class="tabs">
          <div
            @click="activeTab = 'user'"
            class="tab"
            :class="{ active: activeTab == 'user' }"
          >
            Meus dados
          </div>
          <div
            @click="activeTab = 'recipes'"
            class="tab"
            :class="{ active: activeTab == 'recipes' }"
          >
            Minhas receitas
          </div>
        </div>

        <transition enter-active-class="animated fadeInLeft">
          <div v-show="activeTab == 'user'" class="user-data-holder mt-2">
            <div class="input-item">
              <span>Nome *</span>
              <input v-model="user.name" type="text" />
            </div>
            <div class="input-item">
              <span>E-mail *</span>
              <input v-model="user.email" type="email" />
            </div>
            <div class="input-item">
              <span>Telefone</span>
              <input v-model="user.phone" type="text" />
            </div>
            <div class="input-item">
              <span>Empresa</span>
              <input v-model="user.company" type="text" />
            </div>
            <div class="flex-between">
              <div class="input-item">
                <span>Estado *</span>
                <select v-model="user.state">
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </div>

              <div class="input-item">
                <span>Cidade</span>
                <input v-model="user.city" type="text" />
              </div>
            </div>

            <div
              @click="updateProfile"
              class="btn desktop4 mx-auto"
              style="background-color: var(--blue1)"
            >
              <span v-show="!updatingUser">Atualizar dados</span>
              <div v-show="updatingUser" class="loading2 white"></div>
            </div>

            <div class="title pwd mt-3">Trocar senha</div>

            <div class="input-item">
              <span>Senha atual *</span>
              <input v-model="changePass.password" type="password" />
            </div>
            <div class="input-item">
              <span>Nova senha *</span>
              <input v-model="changePass.newPassword" type="password" />
            </div>
            <div class="input-item">
              <span>Confirmar nova senha *</span>
              <input v-model="changePass.confirmPassword" type="password" />
            </div>

            <div
              @click="changePassword"
              class="btn desktop4 mx-auto"
              style="background-color: var(--blue1)"
            >
              <span v-show="!changingPassword">Trocar senha</span>
              <div v-show="changingPassword" class="loading2 white"></div>
            </div>

            <div class="title pwd mt-3">Excluir Conta</div>
            <div
              @click="deleteAccount"
              class="btn desktop4 mx-auto"
              style="background-color: var(--red1)"
            >
              <span v-show="!deletingAccount">Excluir minha conta</span>
              <div v-show="deletingAccount" class="loading2 white"></div>
            </div>
          </div>
        </transition>

        <transition enter-active-class="animated fadeInRight">
          <div v-show="activeTab == 'recipes'">
            <router-link to="/minha-receita/nova" class="recipe-btn">
              <PlusIcon :size="26" :fillColor="'var(--secondary)'" />
              <p>Adicionar nova receita</p>
            </router-link>
            <div class="recipes-holder mt-2">
              <div v-for="recipe in recipes" :key="recipe.id" class="card">
                <div
                  @click="$router.push(`receita/${recipe.slug}`)"
                  class="img-holder"
                  :style="`background-image:url(${recipe.coverImage})`"
                ></div>
                <div class="content">
                  <h1 @click="$router.push(`receita/${recipe.slug}`)">
                    {{ recipe.title }}
                  </h1>
                  <div class="bottom-holder">
                    <div class="reactions">
                      <div class="like-item">
                        <Liked />
                        <p>{{ `(${recipe.likes})` }}</p>
                      </div>
                    </div>
                    <router-link :to="`/minha-receita/${recipe.id}`"
                      >Editar</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import validators from "../util/validators";
import Header from "../components/Header";
import Liked from "vue-material-design-icons/ThumbUp.vue";
import PlusIcon from "vue-material-design-icons/PlusCircleOutline.vue";

export default {
  name: "Home",
  components: {
    Header,
    Liked,
    PlusIcon,
  },
  data() {
    return {
      loading: true,
      activeTab: "user",
      updatingUser: false,
      changingPassword: false,
      deletingAccount: false,
      user: {
        id: "",
        name: "",
        email: "",
        phone: "",
      },
      changePass: {
        password: "",
        newPassword: "",
        confirmPassword: "",
      },
      recipes: [],
    };
  },
  created() {
    this.getUser();
    this.getUserRecipes();
    if (this.$route.query.recipe) this.activeTab = "recipes";
  },
  methods: {
    getUser() {
      this.loading = true;
      http
        .get(
          `user/details?id=${authentication.getUser().info.id}`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.user = response.data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getUserRecipes() {
      this.loading = true;
      http
        .get(
          `user/recipes?take=-1&orderBy=timestamp`,
          authentication.getHeaders()
        )
        .then((response) => {
          this.recipes = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateProfile() {
      if (this.user.name.length < 4) {
        this.showWarning("Nome *", "Por favor, informe seu nome");
        return;
      }
      if (!validators.validateEmail(this.user.email)) {
        this.showWarning("E-mail *", "Por favor, informe um e-mail válido.");
        return;
      }
      if (!this.user.state) {
        this.showWarning("Estado *", "Por favor, selecione o seu estado.");
        return;
      }

      this.updatingUser = true;
      http
        .put("user", this.user, authentication.getHeaders())
        .then((response) => {
          if (response.status === 200) {
            this.$swal({
              icon: "success",
              title: "Sucesso",
              text: "Dados atualizados com sucesso",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.updatingUser = false;
        });
    },
    changePassword() {
      if (!this.changePass.password) {
        this.showWarning("Senha atual *", "Por favor, informe sua senha atual");
        return;
      }
      if (!this.changePass.newPassword) {
        this.showWarning("Nova Senha *", "Por favor, informe sua nova senha");
        return;
      }
      if (!this.changePass.confirmPassword) {
        this.showWarning(
          "Confirmar nova senha *",
          "Por favor, informe a confirmação da sua nova senha"
        );
        return;
      }
      if (this.changePass.newPassword != this.changePass.confirmPassword) {
        this.showWarning(
          "Confirmar nova senha *",
          "Confirmação de senha inválida"
        );
        return;
      }
      this.changingPassword = true;
      http
        .put(
          "user/changePassword",
          this.changePass,
          authentication.getHeaders()
        )
        .then((response) => {
          if (response.status === 200) {
            this.$swal({
              icon: "success",
              title: "Sucesso",
              text: "Senha alterada com sucesso",
            });
          }
        })
        .catch((err) => {
          this.showWarning("Erro", err.response.data);
          console.log(err);
        })
        .finally(() => {
          this.changingPassword = false;
        });
    },
    showWarning(title, text) {
      this.$swal({
        icon: "warning",
        title: title,
        text: text,
      });
    },
    deleteAccount() {
      this.deletingAccount = true;
      this.$swal
        .fire({
          icon: "error",
          title: "Tem certeza que deseja excluir sua conta?",
          text: "Essa ação é irreversível!",
          confirmButtonText: "Sim, excluir",
          confirmButtonColor: "var(--red1)",
          cancelButtonText: "Cancelar",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            http
              .delete("User/" + this.user.id, authentication.getHeaders())
              .then(() => {
                authentication.logout();
              })
              .catch(() => {
                this.showWarning(
                  "Erro",
                  "Não foi possível excluir a conta! Por favor, tente novamente mais tarde."
                );
              })
              .finally(() => {
                this.deletingAccount = false;
              });
          } else {
            this.deletingAccount = false;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 35px auto 0 auto;
  border-bottom: 1px solid #ccc;
  .tab {
    position: relative;
    display: grid;
    place-items: center;
    height: 38px;
    font-size: 1.15em;
    cursor: pointer;
    &.active {
      color: var(--secondary);
      font-family: fontMedium;
      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: var(--secondary);
        left: 0;
        bottom: -1px;
      }
    }
  }
}

.title {
  position: relative;
  color: var(--dark4);
  margin: 15px 0 0 0;
}

.recipes-holder {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.card {
  position: relative;
  width: 100%;
  height: 100px;
  box-shadow: 1px 3px 6px 0 rgba(0, 0, 0, 0.12);
  display: grid;
  grid-template-columns: 100px 1fr;
  border-radius: 6px;
  cursor: pointer;
  .img-holder {
    position: relative;
    width: 100px;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 6px;
  }
  .content {
    position: relative;
    width: 100%;
    padding: 12px;
    h1 {
      font-family: fontLight;
      font-size: 1.2em;
      color: var(--dark4);
    }
  }
  .bottom-holder {
    position: absolute;
    width: calc(100% - 30px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 15px;
    .reactions {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .like-item {
        svg {
          position: relative;
          top: 2px;
        }
      }
    }
    a {
      font-size: 1.05em;
      color: var(--secondary);
    }
    .like-item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
        color: var(--secondary);
      }
      p {
        margin: 0 0 0 6px;
        color: var(--dark5);
      }
    }
  }
}

.recipe-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px auto 0 auto;
  p {
    font-size: 1.15em;
    margin: -2px 0 0 15px;
    color: var(--secondary);
  }
}

@media screen and (min-width: 1160px) {
}
</style>
